import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./style.css";

const Disclaimer = () => {

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} sx={{ px: { xs: 2, md: 10, lg: 30 }, py: { xs: 12, lg: 15 } }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', textTransform: 'uppercase', mb: 1 }}>
                            Disclaimer
                        </Typography>
                        <hr />

                        <Typography variant="h6" sx={{ textAlign: 'auto', textTransform: 'uppercase', mb: 1, fontSize: '12px' }}>
                            This disclaimer (?Disclaimer?) will be applicable to the Website. By using or accessing the Website you agree with the Disclaimer without any qualification or limitation. The Company reserves the right to add, alter or delete the material from the Website at any time and may, at any time, revise these Terms without any notification<br></br><br></br>

                            Any visitor logging or navigating on this Website automatically agrees to comply with and be bound by the following Terms of use, which govern the relationship with visitors in relation to this Website. All visitors are presumed to have read, understood and agreed to Terms of use of this site as given here in below.<br></br><br></br>
                            <ol>
                                <li>Information</li>
                                <ul>
                                    <li>
                                        The information on this Website is presented as general information and no representation or warranty is expressly or impliedly given as to it accuracy, completeness or correctness.
                                    </li>
                                    <li>
                                        The information on this Website does not constitute an offer and / or contract of any such type between the owner/developer and the visitor.

                                    </li>
                                    <li>
                                        This Website contains material which is owned by or licensed by Company and is protected by copyright and intellectual property laws. This material includes, but is not limited to, the design, layout, look, appearance, text and graphics. Reproduction is prohibited.
                                    </li>
                                    <li>
                                        Company has the right to alter the information of this Website and is under no obligation to communicate any changes.
                                    </li>
                                    <li>
                                        The Company uses all diligence, skill and expertise available to provide information on this website but does not accept or undertake any express or implied warranty of any nature whatsoever and the Company disclaims all or any errors and mistakes to the fullest extent.
                                    </li>
                                    <li>
                                        The Company does not warrant that the information offered will be error-free, or that the defects will be corrected, or that this site or the server that makes it available are or will be free of viruses or other harmful components. The Company shall not be under any obligation to ensure compliance or handle complaints.
                                    </li>
                                </ul>
                                <li>
                                    The visitor is presumed to have read the terms and conditions of the website and is deemed to have agreed, understood and accepted unconditionally all the terms, conditions, procedure and risks of logging onto the website and cannot claim, at anytime, ignorance of any or all of them. All relationships of any visitor of this website wheresoever?s situated is governed by and in accordance with the laws and jurisdiction of Mumbai, India.
                                </li>
                                <li>
                                    The visitor has, by the act of logging onto the website and/or submitting information or giving his name, address, email addresses as identification to the Company through the website, on phone, fax or e-mail, deemed to have consented and has expressly and irrevocably authorized the Company to use, reveal, analyze or display and transmit all information and documents as may be required by it. The visitor represents and warrants that he has provided true, accurate, current and complete information about himself and if any information is found to be untrue, inaccurate, not current or incomplete, the Company has the right to take any action it deems appropriate without any limitation.
                                </li>
                                <li>
                                    In case the visitor does not want to receive information from Company he will have to communicate to this effect to Company by sending an email to info@greencanvas.in

                                </li>
                                <li>
                                    The visitor represents and warrants that he/she is fully aware of the laws of the country/state he/she resides in and also those of India particularly those governing use, sale, lease, transfer of real estate and the visitor is neither violating nor attempting to violate any law.

                                </li>
                                <li>
                                    The description of a plot/flat size, site plan, floor plan, a rendering, a photo, the elevation, prices, taxes, adjacent properties, amenities, design guidelines, completion dates, features, zoning, buyer incentives etc. Square footage and room sizes mentioned on Website are approximate and may vary. The Company reserves the right to alter, amend and vary the layout, plans, and specifications or feature without prior notice or obligation, but subject to the approval of the competent authorities as applicable.
                                </li>
                                <li>
                                    The plans, specifications, images and other details herein are only indicative and the Company reserves the right to change any or all of these in the interest of the project/development. The Website does not constitute an offer and/or contract of any nature whatsoever. Any purchase/lease/leave and license in any project shall be governed by the terms of the agreement entered into between the parties and no details mentioned on this website shall govern the transaction. The Company and its respective subsidiaries and affiliates, and their respective officers, directors, partners, employees, agents, managers, trustees, representatives or contractors of any of them, related thereto and any successors or assigns of any of the foregoing shall not be liable for any direct, indirect, actual, punitive, incidental, special, consequential damages or economic loss whatsoever, arising from or related to the use of or reliance on this Website.

                                </li>
                                <li>
                                    Unless otherwise stated, None of the above may be construed to form any basis of or serve as an inducement or invitation for payment of any advance and/or deposit, to be made by a prospective customer, under relevant provisions of law or otherwise and the Company through this website does not intend to make any offer, proposal or contract as per prevailing laws in India or any similar or relevant law in the country of residence or access of the visitor. The terms of offer, payment schedule, amenities/ specifications, features etc. mentioned in the agreement for sale (if any) shall be final. For more information refer to ?https://maharera.mahaonline.gov.in/?

                                </li>
                                <li>
                                    The contents, information and material contain in this website are the exclusive property of the Company and are protected by copyright and intellectual property laws. No person shall use, copy, reproduce, distribute, initiate, publish, display, modify, create derivative works or database, use, transmit, upload, exploit, sell or distribute the same in whole or in part or any part thereof without prior express written permission from the Company. Facility to print an article or portion of text or material on this website through computer/electronic device does not tantamount to prior written consent.

                                </li>
                                <li>
                                    The Company has the right to reproduce, monitor, disclose any transmission or information received and made to this website. Visitors may be sent information or contacted through the email addresses, phone numbers and postal addresses provided by the visitor on the website. Any visitor who may not desire to receive email from the Company may give clear instructions.
                                    <br></br>
                                </li>

                                <li>
                                    The visitor shall not use or post any computer programs in connection with his/her use of the website that contain destructive features such as viruses anomalies, self-destruct mechanisms, time/logic bombs, worm, Trojan horses etc.
                                    <br></br>
                                </li>
                                <li>
                                    Notwithstanding anything, in no event shall the Company, their promoters, directors, employees and agents be liable to the visitor for any or all damages, losses and causes of action (including but not limited to negligence), errors, injury, whether direct, indirect, consequential or incidental, suffered or incurred by any person/s or due to any use and/or inability to use this site or information or its links, hyperlinks, action taken or abstained or any transmission made through this website.
                                    <br></br>
                                </li>
                                <li>
                                    Any links to off-site pages or other sites may be accepted by the visitor at his/her option and own risk and the Company assumes no liability for and shall be held harmless from any resulting damages. The inclusion of any links does not necessarily imply a recommendation or endorsement of the views within them.. The Company strongly recommends that the visitors carefully reads the terms and conditions of such linked site(s).
                                    <br></br>

                                </li>
                                <li>
                                    Every effort is made to keep the Website operative. However Company takes no responsibility, and will not be liable for, the Website or any of its components being temporarily unavailable due to technical issues beyond its control.
                                    <br>
                                    </br>
                                </li>
                                <li>
                                    When visiting our Website, your web browser may produce pop-up advertisements. These advertisements are most likely produced by other Websites or by third party software installed on your computer. Company does not endorse or recommend products or services for which visitor may view a pop-up advertisement on your computer screen while visiting its Website
                                    <br></br>
                                </li>
                                <li>
                                    Company does not warrant that this site will be free of viruses or other harmful components. This Website and emails from Company are vulnerable to data corruption, interception, tampering, viruses as well as delivery error and the visitor accepts liability for any consequences that may arise from them.
                                    <br>
                                    </br>
                                </li>
                                <li>
                                    In no event will Company or any of its employees, managers or representatives shall not be responsible for direct or indirect loss or damage, any consequential damage or economic loss arising or related to the use of the Website. Your use of any information or material on this website is entirely at your own risk, for which Company shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
                                    <br></br>
                                </li>
                                <li>
                                    In no event will the Company be liable for the claim made by the users including seeking any cancellation for any of the inaccuracies in the information provided in this Website, though all efforts have to be made to ensure accuracy. The Company will no circumstance will be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website
                                    <br></br>
                                </li>
                                <li>
                                    The Company reserves the right to terminate, revoke, modify, alter, add, and delete any one or more of the terms and conditions of the website. The Company shall be under no obligation to notify the visitor of the amendment to the terms and conditions and the visitor shall be bound by such amended terms and conditions.
                                    <br></br>
                                </li>
                                <li>
                                    The revised terms and conditions will apply to the use of our website from the date of the publication. Please check this page regularly to ensure you are familiar with the current version
                                </li>
                            </ol>
                        </Typography>



                    </Grid>
                </Grid>
            </Box>

        </>
    );
};

export default Disclaimer;